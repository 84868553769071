import styled from "styled-components";

const CestasImpressaoContainer = styled.div`
  @media screen {
    display: none;
  }
  @media print {
    display: block;
  }
`;

export default CestasImpressaoContainer;
