import moment from "moment";
import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";
import SuccessSRC from "../../../assets/jsons/Success.json";
import { Button } from "../../../components/Button";
import { FooterPaginas } from "../../../components/FooterPaginas/FooterPaginas";
import Form from "../../../components/Form";
import { Input } from "../../../components/Input";
import PopoverInfo from "../../../components/PopoverInfo/PopoverInfo.tsx";
import PrintableSacola from "../../../components/PrintableSacola/PrintableSacola.tsx";
import { SkeletonPedidoCesta } from "../../../components/Skeletons/SkeletonPedidoCesta/SkeletonPedidoCesta";
import TableProdutos from "../../../components/TableProdutos/TableProdutos.tsx";
import { EmpresaContext } from "../../../contexts/Empresa/EmpresaContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext.tsx";
import { CestasAbandonadasApi } from "../../../hooks/cestasAbandonadasApi";
import { cepMask, cnpjMask, cpfMask, phoneMask } from "../../../services/Masks";
import { CestaAbandonada } from "../../../types/CestaAbandonada";
import "./styles.ts";
import { ContainerCesta } from "./styles.ts";
import { SelectPedidos } from "../../../components/Select/SelectPedidos/index.tsx";

interface CestaProps {}

const Cesta: React.FC<CestaProps> = ({}) => {
  const animation = SuccessSRC;

  const cestaApi = CestasAbandonadasApi();
  const {empresa, setEmpresa} = useContext(EmpresaContext);

  const { theme } = useTheme();

  const { id } = useParams<{ id: string }>();
  const [observacaoInterna, setObservacaoInterna] = useState("");
  const [cesta, setCesta] = useState<CestaAbandonada>();
  const [loading, setLoading] = useState(true);
  const [loadingPrinting, setLoadingPrinting] = useState(false);
  const [situacoes, setSituacoes] = useState<
  Array<{ value: number; name: string; quantidade?: number }>
>([]);
const [loadingSkeleton, setLoadingSkeleton] = useState(true);


  const printableRef = useRef<HTMLDivElement>(null);

  const [formValidObs, setFormValidObs] = useState(false);

  const cores = {
    // cores conforme o id da situação no banco
    1: "#4de889", //em aberto
    2: "#e7be00", //em contato
    3: "#ffbe9d", //nao convertido
  };

  const init = async () => {
    if (!id) return;
    const [cesta, situacoes] = await Promise.all([
      cestaApi.getCestaAbandonada(id),
      cestaApi.getSituacoes()
    ]);

    setCesta(cesta);
    setObservacaoInterna(cesta.observacao);
    setLoading(false);

    setSituacoes(
      situacoes.map((situacao: any) => ({
        name: situacao.descricao,
        value: situacao.id.toString(),
      }))
    );
    setLoadingSkeleton(false);
  };

  const handleSituacaoChange = async (situacao: string) => {
    if (!id || cesta?.situacao === situacao) return;
      setLoading(true);
    await cestaApi.alterarSituacao([id], Number.parseInt(situacao)).then(
      (res) => {
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
          title: "Situação da cesta alterada com sucesso!",
        });
        setCesta({ ...cesta!, situacao: situacao });
        setLoading(false);
      },
      (err) => {
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
          title: "Erro ao alterar situação da cesta!",
        });
        setLoading(false);
      }
    );
  };

  const handleOnSubmitObservacaoInterna = async (observacao: string) => {
    id &&
      cestaApi.atualizarObservacaoInternaCesta(id, observacao).then(
        (res) => {
          Swal.fire({
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
            title: "Observação interna atualizada com sucesso!",
          });
        },
        (err) => {
          Swal.fire({
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
            title: "Erro ao atualizar observação interna!",
          });
        },
      );
  };

  const memoizedSacola = useMemo(() => cesta, [cesta]);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {loading ? (
        <SkeletonPedidoCesta />
      ) : (
        <ContainerCesta theme={theme}>
          <div className="flex-container">
            <div className="subtitulo-cesta">
              <span>
                Sacola de{" "}
                {cesta?.cliente?.nome
                  ? cesta.cliente.nome
                  : cesta?.cliente?.razao_social
                  ? cesta.cliente.razao_social
                  : "cliente não identificado"}
              </span>
            </div>
            <span className="select-cesta-unico">
              <SelectPedidos
                options={
                  cesta?.id_situacao ===
                  situacoes
                    .find((situacao) => situacao.name)
                    ?.value.toString()
                    ? situacoes 
                    : situacoes.filter((situacao) => situacao.name)
                }
                loading={loading}
                value={cesta?.id_situacao?.toString()}
                onChange={handleSituacaoChange}
                cores={cores}
              />
            </span> 
          </div>
          <div className="linha"></div>
          <div className="flex-container">
            <div className="titulo-cesta">
              Data:{" "}
              {moment(cesta?.data_modificacao).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            <div className="titulo-cesta">Tabela: {cesta?.tabela || ""}</div>
          </div>
          <div className="container-branco">
            <div className="flex-container">
              {cesta?.cliente.nome || cesta?.cliente.razao_social ? (
                <div className="column-container">
                  <div className="titulo-cesta">Cliente</div>
                  <div className="subtitulo-cesta">
                    <span>
                      {cesta?.cliente.nome ||
                        cesta?.cliente.razao_social ||
                        "Não identificado"}
                    </span>
                  </div>
                </div>
              ) : null}

              {cesta?.cliente.telefone ? (
                <div className="column-container">
                  <div className="titulo-cesta">Telefone</div>
                  <div className="subtitulo-cesta">
                    <a
                      target="blank"
                      href={`https://api.whatsapp.com/send?phone=55${cesta?.cliente.telefone}`}
                    >
                      {phoneMask(cesta?.cliente.telefone)}
                    </a>
                  </div>
                </div>
              ) : null}

              {cesta?.cliente.email ? (
                <div className="column-container">
                  <div className="titulo-cesta">Email</div>
                  <div className="subtitulo-cesta">{cesta?.cliente.email}</div>
                </div>
              ) : null}
            </div>

            <div className="flex-container">
              {cesta?.cliente.cpf || cesta?.cliente.cnpj ? (
                <div className="column-container">
                  <div className="titulo-cesta">CPF/CNPJ</div>
                  <div className="subtitulo-cesta">
                    {cpfMask(cesta?.cliente.cpf || "") ||
                      cnpjMask(cesta?.cliente.cnpj || "")}
                  </div>
                </div>
              ) : null}

              {cesta?.cliente.inscricao_estadual ? (
                <div className="column-container">
                  <div className="titulo-cesta">Inscrição Estadual</div>
                  <div className="subtitulo-cesta">
                    {cesta?.cliente.inscricao_estadual}
                  </div>
                </div>
              ) : null}

              {cesta?.cliente.data_nascimento ? (
                <div className="column-container">
                  <div className="titulo-cesta">Data de nascimento</div>
                  <div className="subtitulo-cesta">
                    {moment(cesta?.cliente.data_nascimento).format(
                      "DD/MM/YYYY",
                    )}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="linha-menor"></div>

            <div className="flex-container">
              {cesta?.cliente.cep ? (
                <div className="column-container">
                  <div className="titulo-cesta">CEP</div>
                  <div className="subtitulo-cesta">
                    {cepMask(cesta?.cliente.cep)}
                  </div>
                </div>
              ) : null}
              {cesta?.cliente.cidade && cesta?.cliente.estado ? (
                <div className="column-container">
                  <div className="titulo-cesta">Cidade</div>
                  <div className="subtitulo-cesta">
                    {cesta?.cliente.cidade} / {cesta?.cliente.estado}
                  </div>
                </div>
              ) : null}
              {cesta?.cliente.bairro ? (
                <div className="column-container">
                  <div className="titulo-cesta">Bairro</div>
                  <div className="subtitulo-cesta">{cesta?.cliente.bairro}</div>
                </div>
              ) : null}

              {cesta?.cliente.endereco || cesta?.cliente.numero ? (
                <div className="column-container">
                  <div className="titulo-cesta">Endereço</div>
                  <div className="subtitulo-cesta">
                    {cesta?.cliente.endereco}, {cesta?.cliente.numero}
                    {cesta?.cliente.complemento
                      ? `, ${cesta?.cliente.complemento}`
                      : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="container-branco">
            <div className="flex-container">
              {cesta?.catalogo?.descricao ? (
                <div className="column-container">
                  <div className="titulo-cesta">Catálogo</div>
                  <div className="subtitulo-cesta">
                    {cesta?.catalogo.descricao}
                  </div>
                </div>
              ) : null}
              {cesta?.vendedor ? (
                <div className="column-container">
                  <div className="titulo-cesta">Vendedor</div>
                  <div className="subtitulo-cesta">{cesta?.vendedor}</div>
                </div>
              ) : null}
              {cesta?.catalogo?.identificacao ? (
                <div className="column-container">
                  <div className="titulo-cesta">Código do catálogo</div>
                  <div className="subtitulo-cesta">
                    {cesta?.catalogo.identificacao}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="container-branco">
            <div className="flex-container">
              {cesta?.valor_total ? (
                <div className="column-container">
                  <div className="titulo-cesta">Valor da cesta</div>
                  <div className="subtitulo-cesta">
                    <span>
                      {`${cesta.moeda} ${Number.parseFloat(cesta?.valor_total).toFixed(2).replace(".", ",")}`}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="container-branco">
            <TableProdutos
              produtos={cesta?.produtos || []}
              moeda={cesta?.moeda || ""}
              template="principal"
            />
          </div>

          <div className="container-branco">
            {cesta?.cliente.observacao && (
              <>
                <div className="flex-container">
                  <div className="column-container">
                    <div className="titulo-cesta">Observação da sacola</div>
                    <div className="subtitulo-cesta">
                      {cesta?.cliente.observacao}
                    </div>
                  </div>
                </div>
                <div className="linha-menor"></div>
              </>
            )}
            <div className="flex-container">
              <div className="titulo-cesta">Observação interna</div>
            </div>
            <Form
              id={"form-gravar-obs-cesta"}
              setFormValid={setFormValidObs}
              onSubmit={() =>
                handleOnSubmitObservacaoInterna(observacaoInterna)
              }
            >
              <div className="obs-gravar">
                <div className="input-div">
                  <Input
                    placeholder="Insira aqui a observação sobre essa sacola. Essa observação não fica visível para o cliente."
                    onChange={(newValue) => setObservacaoInterna(newValue)}
                    value={observacaoInterna}
                    type={"text"}
                  />
                </div>
                <Button type="submit" title="Gravar" />
              </div>
            </Form>
          </div>
          <FooterPaginas>
            <div className="flex-botoes-cesta">
              <ReactToPrint
                onAfterPrint={() => setLoadingPrinting(false)}
                onBeforeGetContent={() => setLoadingPrinting(true)}
                onPrintError={() => {
                  Swal.fire({
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                    title: "Erro ao imprimir pedido!",
                  });
                  setLoadingPrinting(false)
                }}
                trigger={() => (
                  <Button
                    loading={loadingPrinting}
                    className="botao-bottom"
                    padding="5rem"
                    title="Imprimir sacola"
                  />
                )}
                removeAfterPrint={false}
                content={() => printableRef.current}
              />
              <div className="flex-direita">
              <Button
                className="botao-bottom"
                padding="5rem"
                title="Retomar sacola cliente"
                  onClick={() => {
                    const link = `${empresa!.dominio_https}${cesta!.catalogo.identificacao}?cesta=${cesta!.identificacao_cesta}${
                      cesta!.cliente.nome !== "Cliente não identificado" ? "&tipo_cesta=C" : ""
                    }`;

                    navigator.clipboard.writeText(link).then(() => {
                      Swal.fire({
                        title: "Link copiado!",
                        text: "O link da sacola foi copiado para a área de transferência.",
                        icon: "success",
                        confirmButtonText: "OK",
                      });
                    }).catch(() => {
                      Swal.fire({
                        title: "Erro",
                        text: "Ocorreu um erro ao copiar o link. Tente novamente.",
                        icon: "error",
                        confirmButtonText: "OK",
                      });
                    });
                  }}
                />
                <PopoverInfo
                  className="popover1"
                  width="78rem"
                  mensagem="Link da cesta do cliente para você enviar para ele, já virá pré preenchido com as informações que ele adicionou."
                />
                <Button
                  className="botao-bottom"
                  padding="5rem"
                  title="Retomar sacola empresa"
                  onClick={() => {
                    window.open(
                      `${empresa!.dominio_https}${
                        cesta!.catalogo.identificacao
                      }?cesta=${cesta!.identificacao_cesta}`,
                      "_blank",
                    );
                  }}
                />
                <PopoverInfo
                  className="popover2"
                  width="78rem"
                  mensagem="Para você retomar a cesta do cliente, os campos com os dados virá em branco para que você preencha."
                />
              </div>
            </div>
          </FooterPaginas>
        </ContainerCesta>
      )}
      <PrintableSacola sacola={memoizedSacola} ref={printableRef} />
    </>
  );
};

export default Cesta;
