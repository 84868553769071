import React, { memo } from "react";
import PrintableSacola from "../../../components/PrintableSacola/PrintableSacola";
import "./styles";
import CestasImpressaoContainer from "./styles";

interface CestasImpressaoProps {
  cestas: any;
}

const CestasImpressao: React.ForwardRefRenderFunction<
  HTMLDivElement,
  CestasImpressaoProps
> = ({ cestas }, ref) => {
  return (
    <CestasImpressaoContainer ref={ref}>
      {cestas.map((sacola, index) => (
        <PrintableSacola
          key={index}
          sacola={sacola}
        />
      ))}
    </CestasImpressaoContainer>
  );
};

export default memo(React.forwardRef(CestasImpressao));
