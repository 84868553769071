import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as Fallback } from "../../assets/newImages/icons/foto-usuario.svg";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import "./styles.scss";

interface FotoUsuarioProps {
  size: number;
  className?: string;
  onClick?: () => void;
  ativo?: boolean;
}

const FotoUsuario: React.FC<FotoUsuarioProps> = ({
  size,
  className,
  onClick,
  ativo,
}) => {
  // CONTEXTS
  const { empresa, setEmpresa } = useContext(EmpresaContext);
  const theme = useTheme();

  // CONTEXT VARIABLES

  const style = { width: `${size}rem`, height: `${size}rem` };

  const [fallBack, setFallback] = useState(false);

  useEffect(() => {
    setFallback(false);
    setEmpresa({ ...empresa!, logo_empresa: empresa?.logoEmpresa });
  }, [empresa?.logo_empresa]);

  return (
    <>
      {fallBack || empresa?.logoEmpresa === "" ? (
        <Fallback
          onClick={onClick}
          style={style}
          fill={
            ativo
              ? theme.theme.colors.titleColor
              : theme.theme.colors.placeholder
          }
          className={`foto-usuario ${className}`}
        />
      ) : (
        <img
          onClick={onClick}
          className={`foto-usuario ${className}`}
          src={empresa?.logoEmpresa}
          onError={(e: React.ChangeEvent<HTMLImageElement>) => {
            console.log(e);
            setFallback(true);
          }}
          style={style}
        />
      )}
    </>
  );
};

export default FotoUsuario;
