import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingSRC from "../../assets/jsons/Loading.json";
import { ReactComponent as Plus } from "../../assets/newImages/icons/plus.svg";
import BackendInfiniteScroll from "../../components/BackendInfiniteScroll/BackendInfiniteScroll.tsx";
import Catalogo from "../../components/Catalogo/Catalogo";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import { Input } from "../../components/Input";
import Loader from '../../components/Loading/Loader/Loader.tsx';
import LoadingComFundo from "../../components/Loading/LoadingComFundo/LoadingComFundo.tsx";
import { Select } from "../../components/Select";
import SkeletonCatalogos from '../../components/Skeletons/SkeletonCatalogos/SkeletonCatalogos.tsx';
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext.tsx";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { CatalogosApi } from "../../hooks/catalogos";
import { Catalogo as CatalogoType, FiltroCatalogos, FiltroOrdemCatalogos } from "../../types/Catalogo";
import ContainerCatalogosListagem from "./styles.ts";

interface CatalogosProps {}

const Catalogos: React.FC<CatalogosProps> = ({}) => {
  const animation = LoadingSRC;

  const [catalogos, setCatalogos] = useState<CatalogoType[]>(
    [],
  );
  const [semProdutosCadastrados, setSemProdutosCadastrados] =
    useState<boolean>(false);
  const catalogosApi = CatalogosApi();
  const [loading, setLoading] = useState(true); 
  const [loadingCatalogoPrincipal, setLoadingCatalogoPrincipal] =
  useState(false);
  
  const { empresa, setEmpresa } = useContext(EmpresaContext);
  
  const { theme } = useTheme();
  
const [filtroOrdem, setFiltroOrdem] = useState<FiltroOrdemCatalogos>(
  (localStorage.getItem("filtro_2") as FiltroOrdemCatalogos) || "data_criacao_desc"
);
const [filtro, setFiltro] = useState<FiltroCatalogos>(
  (localStorage.getItem("filtro_1") as FiltroCatalogos) || "todos"
);
  const [filtroPesquisa, setFiltroPesquisa] = useState<string>("");
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
  const page = useRef<number>(0);
  const fetching = useRef<boolean>(false);
  const limit = 10;

  const [filtroPesquisaAux, setFiltroPesquisaAux] = useState<string>("");

  const optionsFiltroOrdem: {name: string, value: FiltroOrdemCatalogos}[] = [
    { name: "Mais recentes", value: "data_criacao_desc" },
    { name: "Título A-Z", value: "titulo_a_z" },
    { name: "Título Z-A", value: "titulo_z_a" },
    { name: "Usuário A-Z", value: "usuario_a_z" },
    { name: "Usuário Z-A", value: "usuario_z_a" },
  ];

  const optionsFiltro: {name: string, value: FiltroCatalogos}[] = [
    { name: "Todos", value: "todos" },
    { name: "Ativos", value: "ativos" },
    { name: "Expirados", value: "expirados" },
    { name: "Replicados", value: "replicados" },
  ];

  const fetchCatalogos = async (
    newFiltroPesquisa: string = "",
    newFiltro: FiltroCatalogos = "todos",
    newFiltroOrdem: FiltroOrdemCatalogos = "data_criacao_desc"
  ) => {
    if(semProdutosCadastrados || fetching.current) return;
    fetching.current = true;

    try {
      page.current = page.current + 1;
      const newCatalogos = (await catalogosApi.getCatalogos(
        newFiltroPesquisa,
        newFiltro,
        newFiltroOrdem,
        limit,
        (page.current - 1) * limit,
      )).catalogos;

      if (newCatalogos.length < limit) {
        setHasMoreItems(false);
      }

      setCatalogos((oldCatalogos) => page.current === 1 ? newCatalogos : [...oldCatalogos, ...newCatalogos]);
    } catch (error: any) {
      if(error.response.data.error === "SEM_PRODUTOS"){
        setSemProdutosCadastrados(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Erro ao buscar catálogos, tente atualizar a página.",
        });
      }
    }
  };

  const handleFilter = async (
    newFilterValue: "todos" | "ativos" | "expirados" | "replicados" | string,
    newSortValue:
      | "recentes"
      | "titulo_a_z"
      | "titulo_z_a"
      | "usuario_a_z"
      | "usuario_z_a"
      | string,
    newSearchValue: string,
  ) => {
    setLoading(true);
    setHasMoreItems(true);
    setFiltro(newFilterValue as FiltroCatalogos);
    setFiltroOrdem(newSortValue as FiltroOrdemCatalogos);
    localStorage.setItem("filtro_1", newFilterValue);
    localStorage.setItem("filtro_2", newSortValue);
    page.current = 0;

    await fetchCatalogos(
      newSearchValue,
      newFilterValue as FiltroCatalogos,
      newSortValue as FiltroOrdemCatalogos
    );
  };

  const handleOnChangeCatalogoPrincipal = async (catalogo: CatalogoType) => {
    Swal.fire({
      icon: "question",
      title: "Deseja definir este catálogo como principal?",
      text: "O Conecta Venda tem como objetivo facilitar suas vendas através de catálogos, por isso é essencial ter um em sua conta. O catálogo principal serve para isso, garantindo que você sempre tenha uma base para apresentar seus produtos.",
      
      showCancelButton: true,
      confirmButtonText: "Definir",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingCatalogoPrincipal(true);
        const newCatalogoPrincipal = await catalogosApi.setCatalogoPrincipal(
          catalogo.id,
        );

        //buscar catalogos novamente para atualizar a lista
        handleFilter(filtro, filtroOrdem, filtroPesquisa);
        
        setEmpresa({
          ...empresa!,
          catalogo_principal: newCatalogoPrincipal.identificacao,
        })
        setLoadingCatalogoPrincipal(false);
      }
    });
  };

  useEffect(() => {
    setLoading(false);
    fetching.current = false;
  }, [catalogos]);

  useEffect(() => {
    handleFilter(filtro, filtroOrdem, filtroPesquisa);
  }, [filtroPesquisa]);

  if (loadingCatalogoPrincipal) {
    return (
      <LoadingComFundo
        mensagem="Definindo catálogo principal..."
        position="fixed"
      />
    );
  }

  return (
    <>
      <ContainerCatalogosListagem theme={theme} semProdutos={semProdutosCadastrados}>
        <div className="flex-header">
          <div className="input">
            <Input
              type={"text"}
              icone="icons/search.svg"
              placeholder={"Pesquisar por catálogo"}
              value={filtroPesquisaAux}
              onChange={(newValue) => {
                newValue === filtroPesquisa
                  ? setLoading(false)
                  : setLoading(true);
                setFiltroPesquisaAux(newValue);
              }}
              onDebounce={(newValue) => setFiltroPesquisa(newValue)}
              debounceTime={500}
            />
          </div>
          <div className="flex-filtros">
            <div className="select">
              <Select
                options={optionsFiltroOrdem}
                value={filtroOrdem}
                onChange={(newValue) =>
                  handleFilter(filtro, newValue, filtroPesquisa)
                }
              />
            </div>
            <div className="select">
              <Select
                options={optionsFiltro}
                value={filtro}
                onChange={(newValue) =>
                  handleFilter(newValue, filtroOrdem, filtroPesquisa)
                }
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="catalogos">
            <SkeletonCatalogos />
          </div>
        ) : (
          <BackendInfiniteScroll
            next={() => fetchCatalogos(
              filtroPesquisa,
              filtro,
              filtroOrdem
            )}
            hasMore={hasMoreItems}
            loader={<Loader />}
          >          
            <div className="catalogos">
              {(!semProdutosCadastrados && !loading) && (
                <>
                  <Link
                    to={"catalogo/0/Novo Catálogo"}
                    className="center hideMobile"
                  >
                    <div className="adicionar_catalogo">
                      <div className="pai">
                        <Plus className="plus" />
                    </div>
                  </div>
                  </Link>
                  <FloatingButton
                    className="hideDesktop"
                    href={"catalogo/0/Novo Catálogo"}
                  />
                </>
              )}
              {catalogos.length === 0 && !loading ? (
                <div className="mensagem">
                  <div className="texto-titulo">
                    <div>
                      {semProdutosCadastrados
                        ? `Você ainda não possui produtos cadastrados!`
                        : `Nenhum resultado encontrado para sua busca.`}
                    </div>
                  </div>
                  <div className="texto-subtitulo">
                    <div>
                      {semProdutosCadastrados ? (
                        <Link className="clique-aqui" to={"/painel/produtos"}>
                          Clique aqui para cadastrar seus produtos.
                        </Link>
                      ) : (
                        `Tente novamente com uma busca diferente!`
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {catalogos.map((catalogo, index) => {
                    return (
                      <div key={index} className="catalogo-wrapper">
                        <Catalogo
                          onChangeCatalogoPrincipal={
                            handleOnChangeCatalogoPrincipal
                          }
                          catalogo={catalogo}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </BackendInfiniteScroll>
        )}
      </ContainerCatalogosListagem>
    </>
  );
};

export default Catalogos;
